/**
 * For script loading that cannot be deferred until document ready
 * Use sparingly
 */
/**
 * Callback for OneTrust cookie consent loading and updates events
 */
window.OptanonWrapper = function () {
    // handle any required logic here
    window.Optanon.OnConsentChanged = function (event) {
    };
};
